<template>
  <v-container fluid style="position:relative;">
    <mx-preloader :loader-status="preloader"/>
    <v-row class="mt-4">
      <v-col class="d-flex">
        <v-btn
            style="padding: 24px 0 25px 0;"
            class="mr-7"
            elevation="2"
            color="primary"
            medium
            :to="{name: 'crm.employees'}"
        >
          <v-icon size="32">mdi-chevron-left</v-icon>
        </v-btn>
        <h1> {{ $t('employee.edit.title') }}</h1>
        <v-btn
            style="padding-top: 24px; padding-bottom: 24px;"
            class="ml-auto"
            elevation="2"
            color="success"
            medium
            @click="userEdit"
        >
          <v-icon size="28">mdi-content-save-outline</v-icon>
        </v-btn>
        <v-btn
            style="padding-top: 24px; padding-bottom: 24px;"
            class="ml-1"
            elevation="2"
            color="red"
            dark
            medium
            @click="dialog = true"
        >
          <v-icon size="28">mdi-trash-can-outline</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" lg="8" md="8">
        <v-card>
          <v-card-title>
            {{ $t('employee.titles.personal_data') }}
          </v-card-title>
          <v-card-text>
            <v-form class="pa-4">
              <v-row>
                <v-col cols="12" md="6" class="d-flex">
                  <v-text-field v-model="form.id"
                                outlined
                                hide-details
                                disabled
                                :label="$t('employee.crm_uid') + ' *'"
                                :placeholder="$t('employee.crm_uid')"
                                required
                                :error="errors.id!==undefined"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field v-model="form.uid"
                                outlined
                                hide-details
                                disabled
                                :label="$t('employee.uid') + ' *'"
                                :placeholder="$t('employee.uid')"
                                required
                                :error="errors.uid!==undefined"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
<!--                <v-col cols="12" md="6" class="d-flex">-->
<!--                  <v-text-field v-model="form.identification"-->
<!--                                outlined-->
<!--                                hide-details-->
<!--                                disabled-->
<!--                                required-->
<!--                                :label="$t('employee.identification') + ' *'"-->
<!--                                :placeholder="$t('employee.identification')"-->
<!--                                :error="errors.identification!==undefined"-->
<!--                  ></v-text-field>-->
<!--                </v-col>-->
                <v-col cols="12" md="6">
                  <v-select v-model="form.gender"
                            :label="$t('employee.gender.title') + ' *'"
                            :items="gender_select"
                            outlined
                            disabled
                            hide-details
                            :append-icon="'mdi-chevron-down'"
                            :menu-props="{ bottom: true, offsetY: true }"
                  ></v-select>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field v-model="form.birthday"
                                outlined
                                hide-details
                                disabled
                                required
                                :label="$t('employee.birthday') + ' *'"
                                :placeholder="$t('employee.birthday')"
                                :error="errors.birthday!==undefined"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field v-model="form.first_name"
                                outlined
                                hide-details
                                disabled
                                :label="$t('employee.first_name') + ' *'"
                                :placeholder="$t('employee.first_name')"
                                required
                                :error="errors.first_name!==undefined"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field v-model="form.last_name"
                                outlined
                                hide-details
                                disabled
                                :label="$t('employee.last_name') + ' *'"
                                :placeholder="$t('employee.last_name')"
                                required
                                :error="errors.last_name!==undefined"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field v-model="form.position"
                                outlined
                                hide-details
                                :label="$t('employee.position') + ' *'"
                                :placeholder="$t('employee.position')"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" md="6">
                  <v-select v-model="form.role"
                            :label="$t('user.role.title') + ' *'"
                            :items="role_select"
                            outlined
                            item-text="display_name"
                            item-value="name"
                            hide-details
                            :append-icon="'mdi-chevron-down'"
                            :menu-props="{ bottom: true, offsetY: true }"
                  ></v-select>
                </v-col>

              </v-row>
              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field v-model="form.email"
                                outlined
                                hide-details
                                type="email"
                                :label="$t('employee.email') + ' *'"
                                :placeholder="$t('employee.email')"
                                required
                                :error="errors.email!==undefined"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field v-model="form.password"
                                outlined
                                hide-details
                                disabled
                                :label="$t('employee.password')"
                                :placeholder="$t('employee.password')"
                  ></v-text-field>
                </v-col>
              </v-row>

            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" lg="4" md="4">
        <v-card class="mb-6">
          <v-card-title>{{ $t('employee.titles.settings') }}</v-card-title>
          <v-card-text>
            <v-switch
                v-model="form.email_send"
                :label="$t('employee.email_send')"
                color="primary"
            ></v-switch>
            <v-switch
                v-model="form.is_blocked"
                :label="$t('employee.is_blocked')"
                color="primary"
            ></v-switch>
          </v-card-text>
        </v-card>
        <v-card class="mb-6">
          <v-card-title>{{ $t('employee.titles.password') }}</v-card-title>
          <v-card-text>
            <PasswordGenerator :status_auto_generate="false" @getPassword="getPassword"/>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-snackbar
        v-model="snackbar_error"
        top
        right
        color="red"
    >
      <template v-if="errors.uid!==undefined">
        {{ $t('employee.create.errors.user_hast') }}
      </template>
      <template v-else>
        {{ $t('employee.create.errors.required_fields') }}
      </template>
    </v-snackbar>
    <v-row justify="center">
      <v-dialog
          v-model="dialog"
          persistent
          max-width="500"
      >
        <v-card class="pa-4">
          <v-card-title class="headline mb-9">
            <h3 style="text-align: center; word-break: break-word;">
              {{ $t('employee.titles.employee_delete') }}
            </h3>
          </v-card-title>
          <v-card-actions class="d-flex justify-center">
            <v-btn color="red" dark @click="userDelete">
              {{ $t('employee.buttons.delete') }}
            </v-btn>
            <v-btn color="grey" dark @click="dialog = false">
              {{ $t('employee.buttons.abort') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-container>
</template>

<script>
import PasswordGenerator from "@/components/PasswordGenerator";
import moment from "moment";

export default {
  components: {PasswordGenerator},
  data() {
    return {
      snackbar_error: false,
      dialog: false,
      preloader: true,
      form: {
        identification: '',
        position: '',
        first_name: '',
        last_name: '',
        email: '',
        password: '',
        gender: '',
        is_blocked: 0,
        email_send: 0,
        role: 'manager'
      },
      role_select: [],
      gender_select: [
        {
          text: this.$t('employee.gender.female'),
          value: 'female'
        },
        {
          text: this.$t('employee.gender.male'),
          value: 'male'
        },
        {
          text: this.$t('employee.gender.divers'),
          value: 'divers'
        },
        {
          text: this.$t('employee.gender.indefinite'),
          value: 'indefinite'
        },
      ],
      errors: {},
    }
  },
  async created() {
    try {
      await this.$store.dispatch('rolesEmployeeLaratrust').then(() => {
        this.role_select = this.$store.getters.roles_employee_laratrust.data
      })
    } catch(error) {
      await this.$store.dispatch('rolesEmployeeLaratrust').then(() => {
        this.role_select = this.$store.getters.roles_employee_laratrust.data
      })
    }

  },
  async mounted() {
    try {
      await this.$store.dispatch('employeeCrmEdit', this.$route.params.id)
      const user = await this.$store.getters.employeeCrm.data
      this.getEMPS(user)
    } catch(error) {
      await this.$store.dispatch('employeeCrmEdit', this.$route.params.id)
      const user = await this.$store.getters.employeeCrm.data
      this.getEMPS(user)
    }

  },
  methods: {
    getEMPS(user) {
      this.form.id = user.id
      this.form.uid = user.uid
      this.form.identification = user.identification
      this.form.first_name = user.first_name
      this.form.last_name = user.last_name
      this.form.birthday = this.getBirthday(user.birthday)
      this.form.gender = user.gender
      this.form.position = user.position
      this.form.email = user.email
      this.form.is_blocked = user.is_blocked
      this.form.role = user.role.name

      this.preloader = false
    },
    getPassword(password) {
      this.form.password = password
    },
    userEdit() {
      this.$store.dispatch('employeeCrmUpdate', this.form).then(() => {
        this.$router.replace({
          name: 'crm.employees'
        })
      }).catch(error => {
        switch (error.response.status) {
          case 422:
            this.errors = error.response.data.errors;
            this.snackbar_error = true
            break;
        }
      })
    },
    userDelete() {
      this.$store.dispatch('employeeCrmDelete', this.form.id).then(() => {
        this.$router.replace({
          name: 'crm.employees'
        })
      }).catch(error => {
        switch (error.response.status) {
          case 422:
            this.errors = error.response.data.errors;
            break;
        }
      })
    },
    getBirthday(date,format='YYYY-MM-DD') {
      return moment(date, format).format('DD.MM.YYYY')
    }
  }
}
</script>
